import coverLoading from '@/components/modals/coverLoading'

export default {
  watch: {
    isCoverLoading (val) {
      val
        ? this._ShowCoverLoading()
        : this._HideCoverLoading()
    }
  },
  computed: {
    isCoverLoading () {
      return this.$store.state.isCoverLoading
    }
  },
  methods: {
    _ShowCoverLoading () {
      this.$modal.show(coverLoading, null, {
        name: 'cover-loading-modal',
        height: 'auto',
        width: 300,
        clickToClose: false
      })
    },
    _HideCoverLoading () {
      this.$modal.hide('cover-loading-modal')
    }
  }
}
