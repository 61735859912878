<template>
  <div class="cover-loading px-40 py-24 bg-white rounded text-grey-darkest">
    <i class="owl-arrow-update icon-loading"></i>
    <span class="leading-normal">{{ $t('msg_wait_loading') }}</span>
  </div>
</template>

<script>
export default {
  name: 'coverLoading'
}
</script>

<style lang="sass" scoped>
.icon-loading
  @apply mr-16
  &::before
    animation: spin 1.5s linear infinite

@keyframes spin
  0%
    transform: rotate(0deg)
  50%
    transform: rotate(180deg)
  100%
    transform: rotate(360deg)
</style>
